import React from 'react'
import '../../App.css'
import HeroSectionSocial from '../HeroSectionSocial';

export default function Socials() {
    return(
        <>
            <HeroSectionSocial />
        </>
        
    )
}