import React from 'react'

const HangmanHeader = () => {
    return (
        <>
            <div className="hangman-header">
                <h1>Animal Hangman</h1>
                <p>Guess the animal - Enter a letter</p>
            </div>
        </>
    )
}

export default HangmanHeader