import React from 'react'
import CardItem from './CardItem';
import './Cards.css';

function BlogCards() {
  return (
    <div className='cards'>
      <h1>Blog</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/logan.jpeg"
              text="Introduction"
              label="3/15/2023"
              path='/blog/introduction'
            />
            <CardItem
              src="images/OpenAI.jpg"
              text="Impact of GPT"
              label="3/28/2023"
              path='/blog/gpt'
            />
          </ul>
          <ul className="cards__items">
          </ul>
        </div>
      </div>
    </div>
  )
}

export default BlogCards