import React from 'react'
import './ArticleIntroduction.css';
import sig from '../srcimages/sig.png'
import space from '../srcimages/spcae.png'

function ArticleIntroduction() {
  return (
    <div className="article">
      <img src={space} alt="signature" />
      <div className="article-text">
        <h1>Introduction</h1>
        <br></br>
        <br></br>
        <p> <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </>
          Heyyo. As you can tell, this website is my portfolio page. What started out as a hobby project to form a hub for my socials and familiarize
          myself with ReactJS has grown into a place for mini games, and now a blog too. All of the blog articles within this page are written by me. Now that I think of it, I may add a little
          signature at the bottom of these articles. Hopefully this one has it. Whatever I write will be about what I'm passionate about, but I will
          try to keep it centered around technology so this does not become a giant twitter thread that takes no critiques. No promises as to how many
          articles I make though! My desire to write these will come and go like an ebb and flow. Full transparency, I did just google 'ebb and flow' to make sure I used
          the term correctly. As some final words to describe myself some more, outside of programming, you'll probably catch me playing volleyball.
        </p>
        <img src={sig} alt="signature" />
      </div>
    </div>
  )
}

export default ArticleIntroduction