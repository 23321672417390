import React from 'react'
import '../../App.css'
import Footer from '../Footer';
import ArticleGPT from '../ArticleGPT';

export default function BlogGPT() {
    return(
        <>
            <ArticleGPT/ >
            <Footer />
        </>
        
    )
}