import React from 'react'
import './ArticleIntroduction.css';
import sig from '../srcimages/sig.png'
import space from '../srcimages/spcae.png'

function ArticleGPT() {
  return (
    <div className="article">
      <img src={space} alt="signature" />
      <div className="article-text">
        <h1>Impact of GPT</h1>
        <br></br>
        <br></br>
        <p> <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </>
          The AI Club at the university I attend provides a space for groups to create and present projects they are passionate about relating to the topic of artificial intelligence.
          One such ongoing project within a group I am a part of has fine-tuned the base model Curie from OpenAI's GPT-3 to test the capabilities of natural language processing within the context of
          student discussion boards. My campus uses a student interface called Canvas, in which many classes ask students to contribute to discussions revolving around a question prompt. The model 
          my group has created, which can be accessed <a href="https://matrrrix-streamlit-example-replyapp-oqokub.streamlit.app/" target="_blank" rel="noopener noreferrer"> here</a>, 
          emulates a student's response to a question of the user's choice. This is just one example of what the GPT model can accomplish. Having the option to build off such an already knowledgeable model makes it 
          much more accessible for people to become involved with machine learning. The necessary layers that must be constructed to train a model are already handled in OpenAI's models, providing further abstraction from 
          what is handled behind the scenes. Machine learning's widespread use is partially due to how approachable the creation of specific models is becoming, and this reach will only grow further with development methods like fine-tuning 
          continuously being made.
        </p>
        <img src={sig} alt="signature" />
      </div>
    </div>
  )
}

export default ArticleGPT