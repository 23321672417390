import React from 'react'
import '../../App.css'
import Footer from '../Footer';
import MinigameCards from '../MinigameCards';

export default function Minigames() {
    return(
        <>
            <MinigameCards/ >
            <Footer />
        </>
        
    )
}