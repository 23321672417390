import React from 'react'
import '../../App.css'
import HangmanGame from '../HangmanGame';
import Footer from '../Footer';

export default function Hangman() {
    return(
        <>
            <HangmanGame/>
            <Footer/>
        </>
        
    )
}