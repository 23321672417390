import React from 'react'
import '../../App.css'
import Footer from '../Footer';
import BlogCards from '../BlogCards';

export default function Blog() {
    return(
        <>
            <BlogCards/ >
            <Footer />
        </>
        
    )
}