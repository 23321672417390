import React from 'react'
import '../App.css';
import './HeroSection.css';
import space from '../srcimages/spcae.png'

function HeroSection() {
    return (
        <div className='hero-container'>
            <img src={space} alt="signature" />
            <video src="/videos/video-1.mp4" autoPlay loop muted playsInline/>
            <h1>Portfolio</h1>
            <p>Feel free to explore</p>
        </div>
    )
}

export default HeroSection