import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import HeroSection from '../HeroSection';

function Home(){
    return(
        <>
            <HeroSection />
            <Footer />
        </>
    )
}

export default Home;